import type { FC } from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import { LinkIcon, PhoneSecondaryIcon } from 'assets'

import styles from './Slider.module.scss'
import { useAppSelector } from 'hooks/useTypedSelector'

const Slider: FC = () => {
  const { settingSlice } = useAppSelector(state => state)

  const Item = ({ banner }: any) => {
    return (
      <div className={styles.wrapper}>
        <img className={styles.wrapper__background} width='100%' src={banner.thumb} />

        <div className={styles.wrapper__container}>
          <div className={styles.wrapper__container__description}>
            <h1 dangerouslySetInnerHTML={{ __html: banner.h1 }} className={styles.wrapper__container__title}></h1>

            <p dangerouslySetInnerHTML={{ __html: banner.description }} className={styles.wrapper__container__text}></p>
          </div>

          {banner?.product_id && (
            <div className={styles.wrapper__container__footer}>
              <div role='button' className={styles.wrapper__container__button}>
                <a href={`/product/${banner?.product_id}`} className={styles.wrapper__container__button__bg}>
                  Перейти до товару
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const items = settingSlice.data?.data?.category?.banners?.map((item: any, index: number) => (
    <Item key={index} banner={item} />
  ))

  return (
    <>
      <AliceCarousel items={items} mouseTracking disableButtonsControls />
    </>
  )
}

export default Slider
